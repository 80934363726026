export const getTableFields = (type = '') => {
  return tables[`ugFields${type}`]
}

const tables = {
  ugFields: [
    { prop: 'created_at', label: 'ДОБАВЛЕНИЕ', width: 130 },
    { prop: 'status', label: 'СТАТУС', width: 80 },
    { prop: 'contract_number', label: 'НОМЕР ДОГОВОРА', minWidth: 120 },
    {
      prop: 'contract_expire_date',
      label: 'ДОГОВОР',
      span: 'ДЕЙСТВУЕТ ДО'
    },
    { prop: 'phone', label: 'ТЕЛЕФОН', minWidth: 120 }
  ],
  ugFieldsMobile: [
    { prop: 'status', label: 'СТАТУС', width: 80 },
    { prop: 'contract_expire_date', label: 'ДОГОВОР', span: 'ДЕЙСТВУЕТ ДО' },
    { prop: 'phone', label: 'ТЕЛЕФОН', minWidth: 120 }
  ]
}

export const description = [
  'На этой странице вы можете сформировать приглашение для ваших перевозчиков.',
  'На этой странице вы можете сформировать приглашение для ваших перевозчиков.' +
    ' Добавьте приглашение и мы отправим email и sms сообщение с приглашением' +
    ' к торгам, уведоми вам на этой странице если перевозчик зарегистировался.' +
    ' Если перевозчик укажет верные данные по телефону и договору, аккредитация' +
    ' перевозчика произойдет автоматически. Приглашение можно отправить срузу' +
    ' всем перевозчикам'
]

export const filterOptions = [
  { id: 1, label: 'Не зарегистрирован', value: 'default' },
  { id: 2, label: 'Зарегистрирован, не аккредитован', value: 'registered' },
  { id: 2, label: 'Аккредитация отклонена', value: 'declined' },
  { id: 2, label: 'Может участвовать в торгах', value: 'accreditated' }
]

export const statuses = {
  default: 'Не зарегистрировался',
  registered: 'Зарегистрировался, не аккредитован',
  declined: 'Аккредитация отклонена',
  accreditated: 'Может участвовать в торгах'
}

export const rules = {
  contract_number: {
    required: true,
    message: 'Необходимо заполнить номер',
    trigger: 'blur'
  },
  contract_date: {
    required: true,
    message: 'Необходимо заполнить дату',
    trigger: 'blur'
  },
  contract_expire_date: {
    required: true,
    message: 'Необходимо заполнить дату',
    trigger: 'blur'
  },
  phone: [
    {
      required: true,
      message: 'Необходимо заполнить телефон',
      trigger: ['blur', 'change']
    },
    {
      min: 18,
      message: 'Телефон заполнен не полностью',
      trigger: ['blur']
    }
  ],
  email: [
    { required: true, message: 'Необходимо заполнить email', trigger: 'blur' },
    {
      type: 'email',
      message: 'Введите корректный email',
      trigger: ['blur']
    }
  ]
}
