<template>
  <page-wrapper title="ПРИГЛАШЕНИЕ ПЕРЕВОЗЧИКОВ В СИСТЕМУ">
    <el-row slot="subtitle" class="fs-20 t-grey fw-l">
      <el-collapse-transition>
        <p>{{ getDescription }}</p>
      </el-collapse-transition>
      <span class="bb cp" @click="isCollapse = !isCollapse ? 1 : 0">
        {{ !isCollapse ? 'развернуть' : 'свернуть' }} описание
      </span>
    </el-row>
    <fast-link-menu />
    <filters
      :filters="filters"
      :options="options"
      @change="onChange"
      @add="onAdd"
    />
    <invitations-table />
    <i-pagination :params="getPagination" @changed="onChangePage" />
    <add-edit-invitation-modal />
    <add-invitations-modal />
  </page-wrapper>
</template>

<script>
import { mapActions, mapGetters, mapMutations } from 'vuex'
import PageWrapper from '@/components/Wrappers/PageWrapper'
import Filters from '@/components/TablesFormsFilters/Filters'
import IPagination from '@/components/Ui/Pagination'
import FastLinkMenu from '@/components/Reusable/FastLinkMenu'
import InvitationsTable from './components/InvitationsTable'
import AddEditInvitationModal from './components/AddEditInvitationModal'
import AddInvitationsModal from './components/AddInvitationsModal'
import { description, filterOptions } from './constants'

export default {
  name: 'Invitations',
  components: {
    FastLinkMenu,
    AddEditInvitationModal,
    AddInvitationsModal,
    PageWrapper,
    Filters,
    IPagination,
    InvitationsTable
  },
  data() {
    return {
      isCollapse: 0,
      filters: {
        'per-page': 20,
        status: '',
        any: ''
      },
      page: 1,
      options: filterOptions
    }
  },
  computed: {
    ...mapGetters('invitations', ['getPagination']),
    getDescription() {
      return description[this.isCollapse]
    }
  },
  created() {
    this.getInvitations()
  },
  methods: {
    ...mapActions('invitations', ['FETCH']),
    ...mapMutations({ setDialog: 'popups/setDialog' }),
    getInvitations() {
      const settings = {
        ...this.filters,
        page: this.page
      }
      return this.FETCH(settings)
    },
    onAdd() {
      this.setDialog({
        name: 'add-edit-invitation-modal',
        visible: true
      })
    },
    onChange(key, val) {
      this.filters[key] = val
      this.getInvitations()
    },
    onChangePage(page) {
      this.page = page
      this.getInvitations()
    }
  },
  beforeDestroy() {
    clearInterval(this.requestInterval)
  }
}
</script>
