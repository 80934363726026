<template>
  <i-dialog :name="name">
    <p slot="title" class="fs-20 fw-b">ПРИГЛАСИТЬ ПЕРЕВОЗЧИКОВ</p>
    <p class="fw-l fs-20 black mt-0">
      Вы можете загрузить одним файлом всех ваших перевозчиков. Файл должен быть
      в любом текстовом формате, сканы и фото не допускаются. Файл должен
      содержать следующие сведения по перевозчикам:
    </p>
    <el-row class="m-30">
      <el-col class="fs-20 black mb-10">Номер договора</el-col>
      <el-col class="fs-20 black mb-10">Дата заключения договора</el-col>
      <el-col class="fs-20 black mb-10">Договор действует до</el-col>
      <el-col class="fs-20 black mb-10">Телефон</el-col>
      <el-col class="fs-20 black mb-10">Email</el-col>
    </el-row>
    <p class="fw-b fs-20 black">
      В течение одного рабочего дня мы обработаем файл и добавим запросы в Ваш
      личный кабинет
    </p>
    <el-row type="flex">
      <el-col :sm="6" :xs="24" class="fs-20 black mb-m-5 mr-70 pt-15">
        Файл со списком
      </el-col>
      <el-col :sm="18" :xs="24">
        <el-upload
          ref="upload"
          :http-request="sendFile"
          :file-list="fileList"
          action=""
          :auto-upload="false"
          :before-upload="beforeUpload"
          :on-change="handleChange"
        >
          <i-button
            :loading="loading"
            :disabled="loading"
            slot="trigger"
            text="Выберите файл"
            width="200"
          />
          <div class="fs-16 accepted mt-20" slot="tip">
            Любой формат, размер до 10 мб
          </div>
        </el-upload>
      </el-col>
    </el-row>
    <el-row type="flex" align="middle" class="mt-50">
      <el-col :sm="7" :xs="24" class="ta-r mr-70">
        <span class="bb cp fs-20 t-grey fw-l" @click="toSingleSend">
          Пригласить по одному
        </span>
      </el-col>
      <el-col :sm="17" :xs="24" class="ta-r">
        <i-button
          :loading="loading"
          :disabled="loading"
          text="Отправить"
          width="200"
          @click="onClick"
        />
      </el-col>
    </el-row>
  </i-dialog>
</template>

<script>
import { mapGetters, mapMutations } from 'vuex'
import ApiFactory from '@/api/ApiFactory'
import { pickerOptions } from '@/data/constants'
import IDialog from '@/components/Ui/Dialog'
import IButton from '@/components/Ui/Button'
import { rules } from '../constants'

const InvitationApi = ApiFactory.get('invitations')

export default {
  name: 'AddInvitationsModal',
  components: { IDialog, IButton },
  data() {
    return {
      rules,
      pickerOptions,
      fileList: [],
      loading: false,
      form: {
        contract_number: '',
        contract_date: '',
        contract_expire_date: '',
        phone: '',
        email: ''
      },
      name: 'add-invitations-modal',
      id: 0
    }
  },
  computed: {
    ...mapGetters({ getDialog: 'popups/getDialog' }),
    item() {
      return this.getDialog(this.name).data || {}
    }
  },
  methods: {
    ...mapMutations({ setDialog: 'popups/setDialog' }),
    toSingleSend() {
      this.fileList = []
      this.setDialog({ name: this.name })
      this.setDialog({
        name: 'add-edit-invitation-modal',
        visible: true
      })
    },
    onClick() {
      this.$refs.upload?.submit()
    },
    beforeUpload(file) {
      const isSize = file.size / 1024 / 1024 < 10
      if (!isSize) {
        this.$message.error(
          `Ошибка при загрузке файла ${file.name} попробуйте еще раз. Превышен размер`
        )
      }
      return isSize
    },
    handleChange(file, fileList) {
      this.fileList = fileList.slice(-1)
    },
    sendFile(files) {
      this.loading = true
      let formData = new FormData()
      formData.append('file', files.file)
      InvitationApi.sendInvitationsFile(formData)
        .then(({ status, data }) => {
          if (status === 200 && data.success) {
            this.onClose('отправлены')
          }
        })
        .catch(err => this.errHandler(err))
        .finally(() => (this.loading = false))
    },
    onClose(text) {
      this.$message.success(`Приглашения успешно ${text}!`)
      this.fileList = []
      this.setDialog({ name: this.name })
    }
  }
}
</script>
