<template>
  <i-dialog :name="name" @close="clearForm">
    <p slot="title" class="fs-20 fw-b">
      {{ title }}
    </p>
    <el-form
      ref="invitationForm"
      :model="form"
      :rules="rules"
      class="i-components mx-20 mb-60"
      @submit.native.prevent
    >
      <el-row type="flex" align="middle" class="f-m-wrap">
        <el-col
          :sm="10"
          :xs="24"
          class="fs-20 pr-15 black mb-m-5 ta-r mr-70 mb-25 ta-m-l"
        >
          Номер договора:
        </el-col>
        <el-col :sm="14" :xs="24">
          <el-form-item prop="contract_number">
            <el-input v-model="form.contract_number" autocomplete="nope" />
          </el-form-item>
        </el-col>
      </el-row>
      <el-row type="flex" align="middle" class="f-m-wrap">
        <el-col
          :sm="10"
          :xs="24"
          class="fs-20 pr-15 black mb-m-5 ta-r mr-70 mb-25 ta-m-l"
        >
          Дата заключения договора:
        </el-col>
        <el-col :sm="14" :xs="24">
          <el-form-item prop="contract_date">
            <el-date-picker
              v-model="form.contract_date"
              :picker-options="{ firstDayOfWeek: 1 }"
              type="date"
              format="dd.MM.yyyy"
              value-format="yyyy-MM-dd"
            />
          </el-form-item>
        </el-col>
      </el-row>
      <el-row
        v-if="!form.is_unlimited"
        type="flex"
        align="middle"
        class="f-m-wrap"
      >
        <el-col
          :sm="10"
          :xs="24"
          class="fs-20 pr-15 black mb-m-5 ta-r mr-70 mb-25 ta-m-l"
        >
          Договор действует до:
        </el-col>
        <el-col :sm="14" :xs="24">
          <el-form-item prop="contract_expire_date">
            <el-date-picker
              v-model="form.contract_expire_date"
              type="date"
              :readonly="!!id"
              :picker-options="pickerOptions"
              value-format="timestamp"
              format="dd.MM.yyyy"
            />
          </el-form-item>
        </el-col>
      </el-row>
      <el-row type="flex" class="mb-25 mt-10 f-m-wrap">
        <el-col :sm="10" class="fs-20 pr-15 mb-m-5 ta-r mr-70" />
        <el-col :sm="14" :xs="24" class="secondary-ch">
          <el-checkbox v-model="form.is_unlimited">
            договор бессрочный
          </el-checkbox>
        </el-col>
      </el-row>
      <el-row type="flex" align="middle" class="f-m-wrap">
        <el-col
          :sm="10"
          :xs="24"
          class="fs-20 pr-15 black mb-m-5 ta-r mr-70 mb-25 ta-m-l"
        >
          Телефон:
        </el-col>
        <el-col :sm="14" :xs="24">
          <el-form-item :prop="!id ? 'phone' : ''">
            <el-input
              v-if="!id"
              v-model="form.phone"
              v-mask="'+7 (###) ###-##-##'"
              autocomplete="nope"
            />
            <el-input v-else :value="phonePreparation(form.phone)" readonly />
          </el-form-item>
        </el-col>
      </el-row>
      <el-row type="flex" align="middle" class="f-m-wrap">
        <el-col
          :sm="10"
          :xs="24"
          class="fs-20 pr-15 black mb-m-5 ta-r mr-70 mb-25 ta-m-l"
        >
          Email:
        </el-col>
        <el-col :sm="14" :xs="24">
          <el-form-item prop="email">
            <el-input
              v-model="form.email"
              :readonly="!!id"
              autocomplete="nope"
            />
          </el-form-item>
        </el-col>
      </el-row>
    </el-form>
    <el-row type="flex" align="middle" class="mt-50">
      <el-col :sm="10" :xs="24" class="ta-r mr-70">
        <span
          v-if="!id"
          class="bb cp fs-20 t-grey fw-l"
          @click="toMassiveSending"
        >
          Массовое приглашение
        </span>
      </el-col>
      <el-col :sm="14" :xs="24" class="ta-r">
        <i-button
          :text="id ? 'Сохранить' : 'Отправить'"
          :loading="loading"
          :disabled="loading"
          width="200"
          @click="onClick"
        />
      </el-col>
    </el-row>
  </i-dialog>
</template>

<script>
import { mapGetters, mapMutations, mapActions } from 'vuex'
import { pickerOptions } from '@/data/constants'
import IDialog from '@/components/Ui/Dialog'
import IButton from '@/components/Ui/Button'
import { rules } from '../constants'
import { isValidDate, phoneRegex } from '@/helpers/functions'

export default {
  name: 'AddEditInvitationModal',
  components: { IDialog, IButton },
  data() {
    return {
      rules,
      pickerOptions,
      form: {
        contract_number: '',
        contract_date: '',
        contract_expire_date: '',
        is_unlimited: '',
        phone: '',
        email: ''
      },
      name: 'add-edit-invitation-modal',
      id: 0
    }
  },
  watch: {
    item: {
      immediate: true,
      handler(val) {
        if (val?.id) {
          const newForm = { ...val }
          this.id = newForm.id
          if (!isValidDate(newForm.contract_date)) newForm.contract_date = ''
          newForm.is_unlimited = !!newForm.is_unlimited
          this.form = this.updateObject(this.form, newForm)
        } else {
          this.id = 0
          this.clearForm()
        }
      }
    }
  },
  computed: {
    ...mapGetters({
      getDialog: 'popups/getDialog',
      getLoading: 'invitations/getLoading'
    }),
    loading() {
      return this.getLoading()
    },
    item() {
      return this.getDialog(this.name).data || {}
    },
    title() {
      return this.id
        ? 'Редактировать приглашение перевозчика'
        : 'Пригласить перевозчика'
    }
  },
  methods: {
    ...mapMutations({ setDialog: 'popups/setDialog' }),
    ...mapActions('invitations', ['CREATE', 'UPDATE']),
    phonePreparation(phone) {
      return phoneRegex(phone)
    },
    onClick() {
      this.$refs.invitationForm.validate(valid => {
        if (valid) {
          const { id } = this
          const data = { ...this.form }
          data.contract_expire_date = data.contract_expire_date / 1000
          data.is_unlimited = data.is_unlimited ? 1 : 0
          id
            ? this.UPDATE({ id, data }).then(res => {
                if (res !== 'error') this.onClose('обновлено')
              })
            : this.CREATE(data).then(res => {
                if (res !== 'error') this.onClose('создано')
              })
        }
      })
    },
    toMassiveSending() {
      this.setDialog({ name: this.name })
      this.setDialog({
        name: 'add-invitations-modal',
        visible: true
      })
    },
    onClose(text) {
      this.$message.success(`Приглашение успешно ${text}!`)
      this.setDialog({ name: this.name })
      this.clearForm()
    },
    clearForm() {
      this.form = {
        contract_number: '',
        contract_date: '',
        contract_expire_date: '',
        is_unlimited: '',
        phone: '',
        email: ''
      }
      this.$refs.invitationForm?.clearValidate()
    }
  }
}
</script>
